<template>
  <TagbyOverlay :show="isProgressing">
    <div>
      <quillEditor
        ref="quillEdit"
        :content="value"
        :options="options"
        :disabled="disabled"
        @input="$emit('input', $event)"
      >
        <div
          id="tagby_editor_toolbar"
          slot="toolbar"
        >
          <div v-if="!disabled">
            <button
              id="tagby_ql_bold"
              class="ql-bold"
            >
              <BTooltip
                target="tagby_ql_bold"
                triggers="hover"
                variant="info"
              >
                bold
              </BTooltip>
            </button>

            <button
              id="tagby_ql_italic"
              class="ql-italic"
            >
              <BTooltip
                target="tagby_ql_italic"
                triggers="hover"
                variant="info"
              >
                italic
              </BTooltip>
            </button>

            <!-- <button
              id="tagby_ql_underline"
              class="ql-underline"
            >
              <BTooltip
                target="tagby_ql_underline"
                triggers="hover"
                variant="info"
              >
                underline
              </BTooltip>
            </button> -->

            <button
              id="tagby_ql_strike"
              class="ql-strike"
            >
              <BTooltip
                target="tagby_ql_strike"
                triggers="hover"
                variant="info"
              >
                strike
              </BTooltip>
            </button>

            <select
              id="tagby_ql_header"
              class="ql-header"
            >
              <option value="1" />
              <option value="2" />
              <!-- <option value="3" />
              <option value="4" />
              <option value="5" />
              <option value="6" /> -->
              <option selected />
              <BTooltip
                target="tagby_ql_header"
                triggers="hover"
                variant="info"
              >
                header
              </BTooltip>
            </select>

            <!-- <select
              id="tagby_ql_font"
              class="ql-font"
            >
              <BTooltip
                target="tagby_ql_font"
                triggers="hover"
                variant="info"
              >
                font
              </BTooltip>
            </select> -->

            <button
              id="tagby_ql_script_sub"
              class="ql-script"
              value="sub"
            >
              <BTooltip
                target="tagby_ql_script_sub"
                triggers="hover"
                variant="info"
              >
                sub
              </BTooltip>
            </button>

            <button
              id="tagby_ql_script_sup"
              class="ql-script"
              value="super"
            >
              <BTooltip
                target="tagby_ql_script_sup"
                triggers="hover"
                variant="info"
              >
                super
              </BTooltip>
            </button>

            <!-- <button
              id="tagby_ql_indent-1"
              class="ql-indent"
              value="-1"
            >
              <BTooltip
                target="tagby_ql_indent-1"
                triggers="hover"
                variant="info"
              >
                indent -1
              </BTooltip>
            </button> -->

            <!-- <button
              id="tagby_ql_indent+1"
              class="ql-indent"
              value="+1"
            >
              <BTooltip
                target="tagby_ql_indent+1"
                triggers="hover"
                variant="info"
              >
                indent +1
              </BTooltip>
            </button> -->

            <select
              id="tagby_ql_color"
              class="ql-color"
            >
              <BTooltip
                target="tagby_ql_color"
                triggers="hover"
                variant="info"
              >
                color
              </BTooltip>
            </select>

            <select
              id="tagby_ql_background"
              class="ql-background"
            >
              <BTooltip
                target="tagby_ql_background"
                triggers="hover"
                variant="info"
              >
                background
              </BTooltip>
            </select>

            <select
              id="tagby_ql_align"
              class="ql-align"
            >
              <option value="" />
              <option value="center" />
              <option value="right" />
              <BTooltip
                target="tagby_ql_align"
                triggers="hover"
                variant="info"
              >
                align
              </BTooltip>
            </select>

            <button
              id="tagby_ql_link"
              class="ql-link"
            >
              <BTooltip
                target="tagby_ql_link"
                triggers="hover"
                variant="info"
              >
                link
              </BTooltip>
            </button>

            <button
              id="tagby_ql_image"
              class="ql-image"
            >
              <BTooltip
                target="tagby_ql_image"
                triggers="hover"
                variant="info"
              >
                image
              </BTooltip>
            </button>

            <button
              id="tagby_ql_code-block"
              class="ql-code-block"
            >
              <BTooltip
                target="tagby_ql_code-block"
                triggers="hover"
                variant="info"
              >
                html
              </BTooltip>
            </button>
          </div>
        </div>
      </quillEditor>
      <form
        id="__tagby_editor_file_form"
        style="display:none"
      >
        <input
          id="__tagby_editor_file"
          type="file"
          style="display:none"
          accept="image/*"
          multiple
        >
      </form>
      <BModal
        ref="codeBlockModal"
        title="HTML코드"
        ok-title="확인"
        ok-variant="primary"
        cancel-title="취소"
        cancel-variant="outline-secondary"
        size="lg"
        centered
        @ok="$emit('input', code)"
      >
        <BFormTextarea
          v-model="code"
          class="tagby-code-block"
          rows="10"
        />
      </BModal>
    </div>
  </TagbyOverlay>

</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {
  BTooltip,
  BFormTextarea,
  BModal,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import useToast from '@/utils/toast'
import uploadFile from '@/utils/uploadFile'
import TagbyOverlay from '@/components/TagbyOverlay.vue'

export default {
  components: {
    BTooltip,
    BFormTextarea,
    BModal,
    quillEditor,
    TagbyOverlay,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { makeToast } = useToast()
    const isProgressing = ref(false)
    const quillEdit = ref()
    const imageHandler = () => {
      const fileEl = document.getElementById('__tagby_editor_file')
      fileEl.click()
    }

    const code = ref()
    const codeBlockModal = ref()
    const codeBlockHandler = () => {
      code.value = props.value
      codeBlockModal.value.show()
    }
    const options = {
      modules: {
        toolbar: {
          container: '#tagby_editor_toolbar',
          handlers: {
            image: imageHandler,
            // align: alignHandler,
            'code-block': codeBlockHandler,
          },
        },
      },
    }

    onMounted(() => {
      const fileEl = document.getElementById('__tagby_editor_file')
      fileEl.addEventListener('input', e => {
        const { files } = e.target
        const fileArray = [...files]
        fileArray.reverse()
        const filesLength = files.length
        const uploadingLogs = {}
        const uploadingReqests = fileArray.map((file, i) => new Promise((resolve, reject) => {
          uploadFile(file, 'product_info', '')
            .then(response => {
              const { file_url: fileUrl } = response
              uploadingLogs[i] = fileUrl
              resolve()
            })
            .catch(() => {
              makeToast('danger', '이미지를 업로드하는데 실패했습니다')
              reject()
            })
        }))

        isProgressing.value = true
        Promise.all(uploadingReqests).then(() => {
          for (let i = 0; i < filesLength; i += 1) {
            const fileUrl = uploadingLogs[i]
            const range = quillEdit.value.quill.getSelection()
            quillEdit.value.quill.insertEmbed(range.index, 'image', fileUrl)
          }
        }).finally(() => {
          isProgressing.value = false
          document.getElementById('__tagby_editor_file_form').reset()
        })
      })
    })

    return {
      options,
      quillEdit,
      code,
      codeBlockModal,
      isProgressing,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style scoped>
.tagby-code-block {
  font-family: monospace;
}
</style>
