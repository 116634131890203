import {
  ref,
} from '@vue/composition-api'

export default {
  title: ref(),
  state: ref('ACTIVE'),
  content: ref(),
  isSaving: ref(false),
}
