<template>
  <TagbyDetailLayout>
    <template #content>
      <NoticeInfo />
    </template>

    <template #side>
      <ActionCard />
    </template>

    <template #hidden />
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import NoticeInfo from './blocks/NoticeInfo'
import ActionCard from './blocks/ActionCard'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    NoticeInfo,
    ActionCard,
  },
  setup() {

  },
})
</script>
