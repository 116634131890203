import axios from '@axios'

export default async function (imgFile, infoTable, purpose) {
  const fileInfo = {
    name: imgFile.name,
    type: imgFile.type,
    size: imgFile.size,
  }
  const formData = new FormData()
  formData.append('file', imgFile)
  formData.append(
    'file_info',
    JSON.stringify(fileInfo),
  )
  formData.append(
    'purpose', purpose,
  )
  const result = await axios.post(
    `${process.env.VUE_APP_ASYNC_BACKEND_SERVER}/file/upload/${infoTable}/`,
    formData,
  )

  return result.data.result.file_info
}
