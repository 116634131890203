<template>
  <TagbyActionCard>
    <template #left>
      <CreateButton />
    </template>
  </TagbyActionCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionCard from '@/components/TagbyActionCard.vue'
import CreateButton from './CreateButton.vue'

export default defineComponent({
  components: {
    TagbyActionCard,
    CreateButton,
  },
  setup() {

  },
})
</script>
