import data from './data'
import actions from './actions'
import useToase from '@/utils/toast'
import router from '@/router'

export default () => {
  const { makeToast } = useToase()
  const createNotice = () => {
    data.isSaving.value = true
    actions.createNotice({
      title: data.title.value,
      content: data.content.value,
      state: data.state.value,
    }).then(response => {
      makeToast('primary', '공지사항 생성에 성공했습니다')
      router.push({ name: 'web-manage-notice-detail', params: { idx: response.data.data } })
    }).catch(() => {
      makeToast('danger', '공지사항 생성에 실패했습니다')
    }).finally(() => {
      data.isSaving.value = false
    })
  }

  const stateOptions = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ]

  return {
    createNotice,
    title: data.title,
    content: data.content,
    state: data.state,
    isSaving: data.isSaving,
    stateOptions,
  }
}
