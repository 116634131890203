<template>
  <BOverlay
    :show="show"
    variant="tranparent"
    opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <slot />
  </BOverlay>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BOverlay,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BOverlay,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

  },
})
</script>
